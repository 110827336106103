import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { OutboundLink } from "gatsby-plugin-google-gtag";
const ProductHighlightDrPepperCreamSoda = () => {
  return (
    <>
      <Row>
        <Col sm={6} md={3} className="text-center">
          <OutboundLink
            rel="sponsored"
            target={`_BLANK`}
            href={`https://www.amazon.com/gp/product/B01KAPWGTW/ref=as_li_tl?ie=UTF8&tag=exclusiveg01c-20&camp=1789&creative=9325&linkCode=as2&creativeASIN=B01KAPWGTW&linkId=1220ebb2ad0853759e2f10f82f43cf0a`}
          >
            <StaticImage src="../images/dr_pepper_original_24_pack_cans.jpg" alt="dr pepper original 24 pack cans" placeholder="blurred" width={300} height={300} />
            <br />
            12 fl oz can 24 Pack
          </OutboundLink>
        </Col>
        <Col sm={6} md={3} className="text-center">
          <OutboundLink
            rel="sponsored"
            target={`_BLANK`}
            href={`https://www.amazon.com/gp/product/B084WPYLML/ref=as_li_tl?ie=UTF8&camp=1789&creative=9325&creativeASIN=B084WPYLML&linkCode=as2&tag=exclusiveg01c-20&linkId=c37684f014830b02eb95ce28c6b5b8fa`}
          >
            <StaticImage src="../images/dr_pepper_cream_soda_12_pack_cans.jpg" alt="dr_pepper_cream_soda_12_pack_cans" placeholder="blurred" width={300} height={300} />
            <br /> 12 fl oz cans, 12 pack
          </OutboundLink>
        </Col>
        <Col sm={6} md={3} className="text-center">
          <OutboundLink
            rel="sponsored"
            target={`_BLANK`}
            href={`https://www.amazon.com/gp/product/B08CY9QVL1/ref=as_li_tl?ie=UTF8&tag=exclusiveg01c-20&camp=1789&creative=9325&linkCode=as2&creativeASIN=B08CY9QVL1&linkId=4430108e968909c7724cdd24e39363b1`}
          >
            <StaticImage src="../images/dr_pepper_cream_soda_20_oz_bottle.jpg" alt="dr pepper cream soda 20 oz bottle" placeholder="blurred" width={300} height={300} />
            <br />
            20 fl oz bottle
          </OutboundLink>
        </Col>
      </Row>
    </>
  );
};
export default ProductHighlightDrPepperCreamSoda;
